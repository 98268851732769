import React from 'react'
import Layout from '../components/layout'
import Grid from '@bit/mui-org.material-ui.grid'

export default function VPPA() {
  return (
    <Layout>
      <section className="inner banner-section">
        <div className="container">
          <div className="caption">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </section>
      <section className="how-it-works-section solar-installations">
        <div className="container">
          <div className="contact-bg">
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              spacing={2}
              style={{ padding: 16 }}
            >
              <p>
                This <strong>VIRTUAL POWER PURCHASE AGREEMENT</strong> is dated
                the 1st day of June 2020 and may be amended from time to time.
              </p>
              <p>
                <strong>THIS AGREEMENT IS BETWEEN:</strong>
              </p>
              <p>
                The user of the website (the "<strong>Subscriber</strong>"); and
              </p>
              <p>
                <strong>CloudSolar Inc.</strong> (Registration No. 44356)
                incorporated under the Laws of Barbados and having its principal
                place of business at Ocean Hotels Corporate Centre, Maxwell Main
                Road, Christ Church in Barbados (the "<strong>Company</strong>
                "),
              </p>
              <p>
                together the <strong>Parties </strong>and each a{' '}
                <strong>Party</strong>.
              </p>
              <p>
                <strong>WHEREAS:</strong>
              </p>
              <ul>
                <li>
                  The Government has set ambitious targets in Barbados for the
                  deployment of new solar power generation capacity at
                  affordable prices;
                </li>
                <li>The Subscriber wishes to enter into:</li>
                <ul>
                  <li>
                    A Digital Solar Investment Agreement (the "
                    <strong>Agreement</strong>"), with the Company in relation
                    to the purchase of Renewable Energy Credits by a remotely
                    installed PV System (the "<strong>Facility</strong>");
                  </li>
                  <li>
                    an Operation, Maintenance and Insurance Agreement with the
                    Company with respect to the provision of the described
                    services for the Facility which includes spare parts;
                  </li>
                </ul>
                <li>
                  In the pursuit of the objectives above, the Company shall sell
                  on behalf of the Subscriber to the utility, its renewable
                  energy output generated by the Facility on the terms set out
                  in this Agreement.
                </li>
              </ul>
              <p>
                <strong>NOW IT IS HEREBY AGREED </strong>as follows
                <strong>:</strong>
              </p>
              <p>
                The Subscriber will purchase a subscription to the energy
                generated from the subscriber determined kilowatt ("kWp") shares
                of the Company&rsquo;s Solar Pool (hereafter called "Solar
                Pool").
              </p>
              <p>This Customer Contract is governed by the laws of Barbados.</p>
              <p>
                <strong>DEFINITIONS </strong>
              </p>
              <p>
                "Solar credit" means the energy credit from the Company to the
                Subscribers' monthly account for the energy produced by their
                subscription(s) to the Solar Pool. Solar credit will be in the
                form of a kilowatt hour (kWh) credit. A kWh credit converts to a
                dollar value based on the Subscribers' Feed-In-Tariff rate.
              </p>
              <p>
                "Solar Pool" shall mean solar generating assets whose combined
                energy production will be used to determine a solar credit
                applied to the Subscribers monthly bill.
              </p>
              <p>
                "Date of Commercial Operation" shall mean the first day of the
                first full calendar month upon which commercial operation is
                achieved for the Subscribers' subscription to the Solar Pool.
              </p>
              <p>
                "Production Month" shall mean the full calendar month for which
                energy is produced by the PV System and delivered to the Company
                at the Production Meter. The energy generated during the
                Production Month will be credited to the Subscribers' next
                monthly account within 10 days.
              </p>
              <p>
                "Solar Photovoltaic System" or "PV System" shall mean all Solar
                PV system components, including but not limited to solar
                modules, inverters, balance of system and meters, contributing
                to the Solar Pool.
              </p>
              <p>
                "Production Meter" shall mean the meter which will record the
                energy generated by the PV system. The readings on the
                Production Meter showing energy generated by the PV System will
                be used to determine the solar credit and the Renewable Energy
                Credits ("REC") generated by the PV System.
              </p>
              <p>
                "Program Year" means the fiscal year of the Solar Pool, or
                October 1 to September 30 of each year.
              </p>
              <p>
                "Share" means the energy generated by the PV system
                proportionate to a 1 kilowatt block of the Solar Pool.
              </p>
              <p>
                "Solar Renewable Energy Credits" or "RECs" or "S-RECs" means the
                renewable energy attributes associated with the Solar Pool.
              </p>
              <p>
                "Subscribed Energy" means electricity generated by the PV System
                attributable to the Subscribers; Subscriptions and delivered to
                the Company at the Production Meter on or after the Date of
                Commercial Operation.
              </p>
              <p>
                "Subscriber" means a retail customer of the Company who owns one
                or more subscriptions of the Solar Pool.
              </p>
              <p>
                "Subscription" means a contract between the Subscriber and the
                Company for energy produced by the PV System. Each subscription
                will consist of the energy produced by 1 kilowatt blocks of the
                PV System.
              </p>
              <p>
                "Term of Contract" means the term of this contract which shall
                begin when this contract is signed by the Subscriber and the
                Company and shall end twenty five (25) years after the Date of
                Commercial Operation, unless otherwise agreed upon by the
                Parties.
              </p>
              <p>
                <strong>AGREEMENT </strong>
              </p>
              <p>The Subscriber and the Company agree:</p>
              <h3>
                1. <strong>Solar Pool Subscription </strong>
              </h3>
              <ul>
                <li>
                  Subject to the terms and conditions set forth in this contract
                  and the Feed In tariff, the Company hereby grants to the
                  Subscriber a subscription to the solar energy output
                  proportionate to the user specified kWp share.
                </li>
                <li>
                  The Subscriber elects a payment option to be facilitated by
                  the website through its Pre Authorized Debit which the
                  Subscriber agrees to while using the site:
                </li>
                <li>
                  A single subscription may not be greater than $25,000 per
                  agreement.
                </li>
              </ul>
              <h3>
                2. <strong>Eligibility/Participation Requirements </strong>
              </h3>
              <ul>
                <li>Subscribers must be in good standing with the Company.</li>
                <li>
                  The Company will limit the participation from commercial
                  customers to no more than thirty percent of the initial Solar
                  Pool offering.
                </li>
                <li>
                  The Company may limit eligible retail customers' participation
                  to the maximum production of the PV System at the Company's
                  discretion.
                </li>
              </ul>
              <h3>
                3. <strong>Subscription Calculation </strong>
              </h3>
              <ul>
                <li>
                  The electric energy produced by the PV Systems will be
                  measured by a Production Meter in kilowatt hours (kWh) for
                  each Production Month. This total energy produced will then be
                  divided by the total available Solar Pool shares to determine
                  the Solar credit for each 1 kWp share. The total Solar credit
                  shall then be multiplied by the number of shares in the
                  Digital Solar Investment Agreement.
                </li>
                <li>
                  The Solar credit will be applied to the Subscribers Account
                  within 5 business days. After 10 business days, the Company
                  reserves the right to adjust the dispersed credits if errors
                  or audit errors are revealed.
                </li>
                <li>
                  The Solar credit calculation will include an adjustment for
                  the management fees of the Renewable Energy Credits from the
                  PV Systems. The management fee adjustment for the Solar Pool
                  is 20% per 1 kWh earned by the Solar Pool.
                </li>
              </ul>
              <h3>
                4. <strong>Billing </strong>
              </h3>
              <ul>
                <li>
                  The Solar credit for each Subscription will vary based on the
                  payment option elected by the Subscriber at the onset of the
                  Solar Pool.
                </li>
                <li>
                  All line items on the bill will be calculated using the net
                  energy produced amount.
                </li>
                <li>
                  Subscribers will not receive any cash payments or monetary
                  credits in the Solar Pool unless they elect CloudSolar to sell
                  the credits to the utility company, which is effected when a
                  withdrawal of the Solar credits from the platform is
                  performed.
                </li>
              </ul>
              <h3>
                5. <strong>Solar Renewable Energy Credits </strong>
              </h3>
              <ul>
                <li>
                  The Subscriber acknowledges and agrees that any environmental
                  attributes, including RECs, associated with the Solar Pool are
                  transferred to the Company through the Solar credit.
                </li>
                <li>
                  The Subscriber acknowledges that the Solar credit includes an
                  adjustment for the management fee of the RECs produced by the
                  PV Systems that are used by the Company for operation of the
                  Solar Pool.
                </li>
              </ul>
              <h3>
                6. <strong>Solar Asset management </strong>
              </h3>
              <ul>
                <li>
                  The Subscriber acknowledges and agrees that the Company and/or
                  parties responsible for operations and maintenance of the
                  Solar Pool assets may, at its sole discretion, repair or
                  replace any equipment as necessary without notice to the
                  Subscriber of such change(s).
                </li>
                <li>
                  In the event of equipment damage resulting in the failure to
                  produce energy, no solar credits will be made to the
                  Subscriber for that portion of the energy output. The Company
                  is held harmless for lost energy production due to operations
                  and maintenance schedules, failures, or repairs.
                </li>
                <li>
                  The Subscriber does not have any claim for ownership of any of
                  the solar energy assets or resources in this contract.
                </li>
                <li>
                  The Subscriber does not assume any cost, responsibility or
                  liability for the ongoing operations and maintenance of the
                  solar energy systems.
                </li>
              </ul>
              <h3>
                7. <strong>Subscription Terms </strong>
              </h3>
              <ul>
                <li>
                  The Solar Pool will have a contract period of 25 years from
                  the date of purchase.
                </li>
                <li>
                  If the Subscriber relocates to another premise, they will be
                  able to continue the Subscription, as is, under the new
                  premise subject to any applicable eligibility requirements.
                </li>
                <li>
                  Either party may terminate this Contract if the other party
                  breaches a material obligation under the Contract and fails to
                  cure the breach within 30 days after receipt of notice of such
                  breach from the non-breaching party.
                </li>
                <li>
                  Upon termination of this Contract by either party: (a) the
                  Subscription will be null and void and of no further force and
                  effect; (b) the Company shall be free to grant the
                  Subscription to another eligible customer.
                </li>
              </ul>
              <h3>
                8. <strong>Subscription Transfer or Cancellation</strong>
              </h3>
              <ul>
                <li>
                  The Subscriber may, at any time, request the transfer of the
                  Subscription, in whole or in part, to another eligible
                  customer, subject to all applicable eligibility requirements.
                </li>
                <li>
                  In order to execute a transfer of the Subscription, the
                  Subscriber is required to provide written notice to the
                  Company within a minimum of 30 days prior to the transfer
                  effective date. The Subscriber should complete a Subscription
                  Transfer Application and submit it to the Company for
                  processing and approval.
                </li>
                <li>
                  If the Subscriber chooses to relinquish the Subscription to
                  the Company, the details will vary based upon which payment
                  option elected by the Subscriber at the onset of the Solar
                  Pool. The relinquishment details for each payment option are
                  described below:
                  <ul>
                    <li>
                      The Company shall refund the Subscriber for a portion of
                      the full subscription payment within 30 days after the
                      date of such termination. The refund amount will be
                      determined using a straight line depreciation schedule
                      described based on 10 years of the life of the solar.
                    </li>
                    <li>
                      In the case of death of a Subscriber, the Subscriber may
                      bequeath the Subscription and upon receipt of transfer
                      paperwork, the Company will transfer the Subscription
                      appropriately on the condition it is transferred to an
                      eligible customer.
                    </li>
                  </ul>
                </li>
              </ul>
              <h3>9. Taxes</h3>
              <p>
                The Company shall be responsible for payment of any and all
                royalties, taxes, fees, or assessments levied against its
                property, leasehold rights or other assets or profits by any
                Authority as may be provided for by any applicable Law and shall
                settle such levies without attempting to recover them from the
                other Party except through the Energy Charge or the Deemed
                Energy Payments.
              </p>
              <h3>10. Force Majeure</h3>
              <ul>
                <li>
                  <strong>
                    Responsibilities of the Parties during a Force Majeure Event
                  </strong>
                  <ul>
                    <li>
                      If a Force Majeure Event occurs the Affected Party shall
                      deliver a written notice on its platform to the
                      Non-Affected Party ("<strong>Force Majeure Notice</strong>
                      ") as soon as reasonably practical of:
                    </li>
                    <li>
                      the date of commencement of the Force Majeure Event;
                    </li>
                    <li>
                      the nature and expected duration of the Force Majeure
                      Event; and
                    </li>
                    <li>
                      the actual and anticipated effect of the Force Majeure
                      Event on the performance by the Affected Party of its
                      obligations under this
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>The Affected Party shall:</strong>
                  <ul>
                    <li>
                      make all reasonable efforts to prevent and reduce to a
                      minimum and mitigate the effect of any delay caused by any
                      Force Majeure Event;
                    </li>
                    <li>
                      take any action in accordance with the standards of a
                      Reasonable and Prudent Operator to ensure resumption of
                      normal performance of this Agreement after the cessation
                      of any Force Majeure Event as promptly as possible and
                      otherwise perform its obligations in accordance with this
                      Agreement; and for the duration of any Force Majeure
                      Event, regularly (and in any event upon the Non-Affected
                      Party's reasonable request) provide the Non-Affected Party
                      with updates in relation to the Force Majeure Event.
                    </li>
                    <li>
                      Not later than seven (7) Business Days following the
                      cessation of any Force Majeure Event, the Affected Party
                      must notify the Non-Affected Party of the cessation of the
                      Force Majeure Event and shall submit to the Non-Affected
                      Party reasonable proof of the nature of the Force Majeure
                      Event and its effect on the performance by the Affected
                      Party of its obligations under this
                    </li>
                  </ul>
                </li>
              </ul>
              <h3>11. Effect of a Force Majeure Event</h3>
              <ul>
                <li>
                  The Affected Party will be excused from performance of its
                  obligations under this Agreement to the extent that
                  performance is impeded or prevented due to a Force Majeure
                  Event and will not be liable for the non-performance of such
                  obligation during the period of a Force Majeure Event.
                </li>
                <li>
                  Notwithstanding the existence of any Force Majeure Event, the
                  Affected Party shall however continue to perform all of its
                  obligations under this Agreement, which are not affected by
                  such Force Majeure Event in accordance with this Agreement,
                  which includes payments of credits by the PV systems which are
                  not affected by a Force Majeure Event, if any.
                </li>
              </ul>
              <h3>12. No Liability for Other Losses</h3>
              <p>
                Save and except as expressly provided in this Agreement, no
                Party shall be liable in any manner whatsoever to the other
                Party in respect of any loss relating to or arising out of the
                occurrence or existence of any Force Majeure Event or the
                exercise by it of any right pursuant to this Clause 11 (
                <em>Force Majeure</em>) above.
              </p>
              <h3>
                13. <strong>Indemnities</strong>
              </h3>
              <p>
                Each Party (the "<strong>Indemnifying Party</strong>") shall
                indemnify and hold harmless the other Party, its Affiliates and
                their respective officers, employees, consultants, agents and
                representatives (the "<strong>Indemnified Parties</strong>")
                against any and all Losses which may be asserted against or
                suffered by any of the Indemnified Parties arising in respect or
                as a consequence of: Any death, injury, loss or damage to
                property suffered by a third party, to the extent resulting from
                any negligent act or omission of the Indemnifying Party and its
                respective officers, employees, consultants, agents and
                representatives, provided that the death, injury, loss or damage
                to property suffered by the relevant third party is not
                attributable to any act or omission of any one or more of the
                Indemnified Parties or to the failure of one or more of the
                Indemnified Parties to use reasonable endeavours to mitigate or
                avoid the death, injury, loss or damage to property in question.
              </p>
            </Grid>
          </div>
        </div>
      </section>
    </Layout>
  )
}
